import * as React from 'react';
import { DisplayHistoryDrawer } from '~/src/features/displays';
import { ProductPreview, ProductRow } from '..';
import './DisplayTable.scss';

type DisplayTableProps = {
  order: any;
  displayOrderDetails: any;
  onDelete: (orderDetailPk: number) => Promise<void>;
  onReorder: (displayDetailPk: string) => void;
  onEdit: (displayDetailPk: string) => void;
  onUpdate: (orderDetail: any) => void;
};

export const DisplayTable = (props: DisplayTableProps) => {
  const [displayPreviewProps, setDisplayPreviewProps] = React.useState<{
    isOpen: boolean;
    product?: any;
  }>({ isOpen: false });
  const [displayDetailsDrawer, setDisplayDetailsDrawer] = React.useState({
    open: false,
    displayId: '',
  });

  function renderTable(orderDetails: any[], index: number, displayDetailsPk: string) {
    return (
      <table className="data-table OrderDetails__orderDetailsTable" key={index}>
        <colgroup className="DisplayTable__colgroup">
          <col className="DisplayTable__colgroup__medium" />
          <col className="DisplayTable__colgroup__medium" />
          <col className="DisplayTable__colgroup__grow" />
          <col className="DisplayTable__colgroup__input" />
          <col className="DisplayTable__colgroup__input" />
          <col className="DisplayTable__colgroup__input" />
          <col className="DisplayTable__colgroup__input" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>Type</th>
            <th>Product #</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit price</th>
            <th>Commission</th>
            <th>Sub-total</th>
            <th>
              <a onClick={() => props.onEdit(displayDetailsPk)}>Edit</a>
            </th>
          </tr>
        </thead>
        <tbody>
          {orderDetails.map((edge: any, index: number) => renderDisplayDetails(edge.node, index, index === 0))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              Total:{' '}
              {orderDetails
                .reduce((prev: number, curr: any) => {
                  const nodeSubtotal = parseFloat(curr.node.quantity) * parseFloat(curr.node.unitPrice) || 0;
                  return prev + nodeSubtotal;
                }, 0)
                .toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </td>
            {/* <td>
              <a onClick={() => props.onReorder(displayDetailsPk)}>Reorder</a>
            </td> */}
          </tr>
        </tbody>
      </table>
    );
  }

  function renderDisplayDetails(node: any, index: number, showDisplayType: boolean) {
    return (
      <ProductRow
        key={index}
        orderDetails={node}
        orderId={props.order.id}
        onDelete={(orderDetailsPk) => props.onDelete(orderDetailsPk)}
        openProductPreview={(product) => setDisplayPreviewProps({ isOpen: true, product })}
        type="display"
        showDisplayType={showDisplayType}
        onUpdate={props.onUpdate}
      />
    );
  }

  return (
    <div className="DisplayTable">
      <>
        {props.displayOrderDetails
          .map((edge: any) => edge.node.displayDetails?.pk)
          .reduce((acc: string[], curr: string) => (acc.includes(curr) ? acc : [...acc, curr]), [])
          .map((pk: string, index: number) =>
            renderTable(
              props.displayOrderDetails.filter((edge: any) => edge.node.displayDetails?.pk === pk),
              index,
              pk
            )
          )}
      </>

      <DisplayHistoryDrawer
        isOpen={displayDetailsDrawer.open}
        onClose={() => setDisplayDetailsDrawer({ open: false, displayId: '' })}
        displayId={displayDetailsDrawer.displayId}
      />
      <ProductPreview
        isOpen={displayPreviewProps.isOpen}
        product={displayPreviewProps.product}
        onClose={() => setDisplayPreviewProps({ isOpen: false })}
      />
      {/* <ReorderModal
        isOpen={toggleReorderModal}
        onClose={() => {
          setToggleReorderModal(false);
        }}
      /> */}
    </div>
  );
};
