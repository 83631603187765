import { useLazyQuery } from '@apollo/client';
import { ArrowPathIcon, EyeIcon, FunnelIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import * as React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Container,
  DataTable,
  DebouncedInput,
  ListPageHeader,
  Nav,
  Pagination,
  Select,
  Spinner,
} from '~/src/components';
import { formatDateString, toFixed2 } from '~/src/utils/formatting';
import { ORDERS_QUERY } from '../../api';
import { CreateOrderDrawer } from '../../components';
import './Orders.scss';

const PAGE_SIZE = 30;

type OrdersFilters = {
  orderDate_Gte: Date | undefined;
  orderDate_Lte: Date | undefined;
  page: number;
  search: string;
  sort: string;
  status: string;
  account?: string;
  rep?: string;
};

const defaultFilters: OrdersFilters = {
  orderDate_Gte: undefined,
  orderDate_Lte: undefined,
  page: 1,
  search: '',
  sort: '-id',
  status: '',
};

const tableHeaders = [
  { label: 'Invoice', sort: 'id', size: 'sm' },
  { label: 'Account', sort: 'account', size: 'md' },
  { label: 'City', sort: 'city', size: 'sm' },
  { label: 'State', sort: 'state', size: 'sm' },
  { label: 'Order Rep', sort: 'rep', size: 'md' },
  { label: 'PO Number', sort: 'po_number', size: 'md' },
  { label: 'Order Date', sort: 'order_date', size: 'sm' },
  { label: 'Ship Date', sort: 'ship_date', size: 'sm' },
  { label: 'Paid Date', sort: 'paid_date', size: 'sm' },
  { label: 'Invoice Total', sort: 'annotated_invoice_total', size: 'sm' },
  { label: 'Status', sort: 'status', size: 'sm' },
];

export const Orders = () => {
  const [searchInput, setSearchInput] = React.useState(defaultFilters.search);
  const [filters, setFilters] = React.useState(defaultFilters);

  const statusOptions = [
    '',
    'Awaiting Approval',
    'Unreleased',
    'In Production',
    'Ready to Ship',
    'Shipped',
    'Paid',
    'Future Order',
    'Canceled',
    'Unknown',
  ].map((status) => {
    return {
      label: status,
      value: status,
    };
  });

  const [createOrderOpen, setCreateOrderOpen] = React.useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchOrders, { data, loading }] = useLazyQuery(ORDERS_QUERY, {
    variables: {
      first: PAGE_SIZE,
      offset: (filters.page - 1) * PAGE_SIZE,
      orderDate_Gte: filters.orderDate_Gte && format(filters.orderDate_Gte, 'yyyy-MM-dd'),
      orderDate_Lte: filters.orderDate_Lte && format(filters.orderDate_Lte, 'yyyy-MM-dd'),
      search: filters.search,
      sort: filters.sort,
      status: filters.status,
      account: filters.account,
      rep: filters.rep,
    },
  });

  React.useEffect(() => {
    const search = searchParams.get('search');
    if (!search) return;
    setSearchInput(search);
    setFilters((p) => ({ ...p, search }));
  }, [searchParams]);

  React.useEffect(() => {
    searchOrders();
    setSearchInput(filters.search);
  }, [filters]);

  function handleSearchDebounce(search: string) {
    setFilters((p) => ({ ...p, search, page: 1 }));
  }

  function setStatusCallback(e: React.ChangeEvent<HTMLInputElement>) {
    setFilters((p) => ({ ...p, status: e.target.value, page: 1 }));
  }

  function renderStatusBadge(status: string) {
    const COLOR_MAP: {
      [key: string]: string | undefined;
    } = {
      'Awaiting Approval': 'success',
      'Unreleased': 'light',
      'In Production': 'primary',
      'Ready to Ship': 'invertedPurple',
      'Shipped': 'invertedSuccess',
      'Paid': 'invertedSuccess',
      'Future Order': 'danger',
      'Canceled': 'invertedDanger',
    };
    return <Badge color={COLOR_MAP[status] as any} label={status} />;
  }

  function renderTableItems() {
    if (!data) return;
    return data.orders.edges.map((order: any, index: number) => {
      return (
        <tr key={index}>
          <td>
            <Link to={`/orders/${order.node.id}`}>{order.node.pk}</Link>
          </td>
          <td>
            {order.node.account && renderFilterOrViewPopover(
              order.node.account?.name,
              () => setFilters((p) => ({ ...p, account: order.node.account?.id })),
              () => navigate(`/accounts/${order.node.account?.pk}`)
            )}
          </td>
          <td>{order.node.account?.city}</td>
          <td>{order.node.account?.state}</td>
          <td>
            {order.node.rep && renderFilterOrViewPopover(
              `${order.node.rep?.user.firstName} ${order.node.rep?.user.lastName}`,
              () => setFilters((p) => ({ ...p, rep: order.node.rep?.id })),
              () => navigate(`/reps/${order.node.rep?.pk}`)
            )}
          </td>
          <td>{order.node.poNumber}</td>
          <td>{formatDateString(order.node.orderDate)}</td>
          <td>{formatDateString(order.node.shipDate)}</td>
          <td>{formatDateString(order.node.paidDate)}</td>
          <td>{toFixed2(order.node.invoiceTotal)}</td>
          <td>{renderStatusBadge(order.node.status)}</td>
        </tr>
      );
    });
  }

  function renderFilterOrViewPopover(label: string, onFilter: () => void, onView: () => void) {
    return (
      <div className="Orders__filterOrViewPopover">
        <a className="Orders__filterOrViewPopover__label">{label}</a>
        <div className="Orders__filterOrViewPopover__options">
          <a onClick={onFilter}>
            <FunnelIcon />
          </a>
          <a onClick={onView}>
            <EyeIcon />
          </a>
        </div>
      </div>
    );
  }

  function nonDefaultFilters() {
    return Object.entries(filters).filter(([key, value]) => value !== defaultFilters[key as keyof OrdersFilters]);
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Orders</title>
      </Helmet>
      <Nav />
      <Container className="Orders">
        <ListPageHeader title="View Orders">
          <Button
            className="ml-4"
            color="primary"
            iconLeading={<ArrowPathIcon />}
            onClick={() => searchOrders({ fetchPolicy: 'network-only' })}
            variant="raised"
          >
            Refresh
          </Button>
        </ListPageHeader>
        <div className="Orders__filters">
          <DebouncedInput
            iconTrailing={<MagnifyingGlassIcon />}
            onChange={(e) => setSearchInput(e.target.value)}
            onDebounce={(value) => handleSearchDebounce(value)}
            placeholder="Search"
            value={searchInput}
          />
          <div>
            <label>Order status</label>
            <Select options={statusOptions} onChange={setStatusCallback} value={filters.status || ''} />
          </div>
          {/* <div>
            <label>Date range</label>
            <DatePicker
              onChange={(e) => setFilters((p) => ({ ...p, orderDate_Gte: e }))}
              value={filters.orderDate_Gte}
            />
          </div>
          <DatePicker value={filters.orderDate_Lte} onChange={(e) => setFilters((p) => ({ ...p, orderDate_Lte: e }))} /> */}
          <div className="flex-1"></div>
          {nonDefaultFilters().length > 0 && (
            <Button color="dark" variant="raised" onClick={() => setFilters(defaultFilters)}>
              Clear Filters
            </Button>
          )}
          <Button color="primary" iconLeading={<PlusIcon />} onClick={() => setCreateOrderOpen(true)} variant="raised">
            New order
          </Button>
        </div>
        {loading || !data ? (
          <Container>
            <Spinner message="Loading orders..." />
          </Container>
        ) : (
          <>
            <DataTable
              headers={tableHeaders}
              sort={filters.sort}
              onSortChange={(sort) => setFilters((p) => ({ ...p, sort, page: 1 }))}
            >
              {renderTableItems()}
            </DataTable>
            <Pagination
              page={filters.page}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => setFilters((p) => ({ ...p, page }))}
              hasNextPage={data.orders?.pageInfo.hasNextPage}
              totalNodes={data.orders?.totalNodes}
              totalNodesOnPage={data.orders?.totalNodesOnPage}
            />
          </>
        )}
      </Container>
      <CreateOrderDrawer
        isOpen={createOrderOpen}
        onClose={() => {
          setCreateOrderOpen(false);
        }}
      />
    </>
  );
};
